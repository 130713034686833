import { useRouter } from "next/router";
import { useClient } from "../../../hooks/useClientState";
import { MutableRefObject, useEffect, useRef } from "react";
import { useRootApp } from "../../../hooks/useRootAppState/useRootAppState";
import config from "../../../config/app";

const Footer = (props: any) => {
  const router = useRouter();
  const rootApp = useRootApp();


  const cdata = useClient();

  const termAndConditions = useRef(null);
  const franchise = useRef(null);
  const contact = useRef(null);
  const feedback = useRef(null);
  const faq = useRef(null);
  const downloadMenu = useRef(null);
  const nutritionalInfo = useRef(null);
  const privacyPolicy = useRef(null);

  const footerContent =
    cdata.data.clientMeta?.styleJson?.footerContent ||
    "Copyright © 2023 Roman's Pizza. All rights reserved.";

  const feedbackEmail = `mailto:${
    cdata.data.clientMeta?.styleJson?.feedbackEmail ||
    "feedback@serveup.software"
  }`;

  const pageRefMaps: Record<string, MutableRefObject<null>> = {
    termAndConditions: termAndConditions,
    franchise: franchise,
    contact: contact,
    feedback: feedback,
    faq: faq,
    downloadMenu: downloadMenu,
    nutritionalInfo: nutritionalInfo,
    privacyPolicy: privacyPolicy,
  };

  useEffect(() => {
    const footerMask = cdata.data.clientMeta?.styleJson?.footerMask || [];

    footerMask.forEach((mask) => {
      const maskedRef = pageRefMaps[mask];

      const elem = maskedRef.current as unknown as HTMLElement;
      if (!elem) {
        return;
      }
      elem.hidden = true;
    });
  }, [cdata]);

  const navigateTo = (link: any) => {
    const { firstTimeSignUp, firstTimeSignUpFlag } = props;
    if (firstTimeSignUpFlag) {
      firstTimeSignUp({ firstTimeSignup: false });
    }
    // firstTimeSignUp({ firstTimeSignup: false });
    router.push(link);
  };

  return (
    <div>
      <div className="flex p-[20px] flex-col items-start gap-[20px] self-stretch md:max-w-[1100px] md:w-full md:mx-auto">
        <div className="flex justify-between items-start self-stretch">
          <p className="text-[16px] font-semibold font-font-family-body">
            Legal
          </p>
          <div className="flex w-[16px] h-[16px] py-[1px] justify-center items-center my-auto relative">
            <svg>
              <use href="/sprites/sprites.svg#whiteArrow" />
            </svg>
          </div>
        </div>
        <div
          className="flex justify-between items-start self-stretch cursor-pointer"
          onClick={() => navigateTo("/franchise")}
        >
          <p className="text-[16px] font-semibold font-font-family-body">
            Franchise
          </p>
          <div className="flex w-[16px] h-[16px] py-[1px] justify-center items-center my-auto relative">
            <svg>
              <use href="/sprites/sprites.svg#whiteArrow" />
            </svg>
          </div>
        </div>
        <div
          className="flex justify-between items-start self-stretch cursor-pointer"
          onClick={() => navigateTo("/faq")}
        >
          <p className="text-[16px] font-semibold font-font-family-body">
            Frequently asked questions
          </p>
          <div className="flex w-[16px] h-[16px] py-[1px] justify-center items-center my-auto relative">
            <svg>
              <use href="/sprites/sprites.svg#whiteArrow" />
            </svg>
          </div>
        </div>
        <div className="flex justify-between items-start self-stretch">
          <p className="text-[16px] font-semibold font-font-family-body">
            Feedback
          </p>
          <div className="flex w-[16px] h-[16px] py-[1px] justify-center items-center my-auto relative">
            <svg>
              <use href="/sprites/sprites.svg#whiteArrow" />
            </svg>
          </div>
        </div>
        <a
          ref={downloadMenu}
          className="w-full"
          target="_blank"
          href="https://serveup.ams3.digitaloceanspaces.com/web-ecommerce/documents/Menu.pdf"
        >
          <div className="flex justify-between items-start self-stretch">
            <p className="text-[16px] font-semibold font-font-family-body">
              Download menu
            </p>
            <div className="flex w-[16px] h-[16px] py-[1px] justify-center items-center my-auto relative">
              <svg>
                <use href="/sprites/sprites.svg#whiteArrow" />
              </svg>
            </div>
          </div>
        </a>

        <a
          ref={downloadMenu}
          className="w-full"
          target="_blank"
          href="https://serveup.ams3.digitaloceanspaces.com/web-ecommerce/documents/NutritionalAnalysis.pdf"
        >
          <div className="flex justify-between items-start self-stretch">
            <p className="text-[16px] font-semibold font-font-family-body">
              Nutritional info
            </p>
            <div className="flex w-[16px] h-[16px] py-[1px] justify-center items-center my-auto relative">
              <svg>
                <use href="/sprites/sprites.svg#whiteArrow" />
              </svg>
            </div>
          </div>
        </a>

        <div className="flex items-center gap-[8px] w-fit mx-auto">
          <div className="w-[135px] h-[40px] relative">
            <svg>
              <use href="/sprites/sprites.svg#playStore" />
            </svg>
          </div>
          <div className="w-[119.665px] h-[40px] relative">
            <svg>
              <use href="/sprites/sprites.svg#appStore" />
            </svg>
          </div>
        </div>
        <div className="flex gap-[20px] items-center w-fit mx-auto">
          <div className="w-[20px] h-[20px] relative">
            <svg>
              <use href="/sprites/sprites.svg#whatsapp" />
            </svg>
          </div>
          <div className="w-[20px] h-[20px] relative">
            <svg>
              <use href="/sprites/sprites.svg#facebook" />
            </svg>
          </div>
          <div className="w-[20px] h-[20px] relative">
            <svg>
              <use href="/sprites/sprites.svg#x" />
            </svg>
          </div>
          <div className="w-[20px] h-[20px] relative">
            <svg>
              <use href="/sprites/sprites.svg#instagram" />
            </svg>
          </div>

          {config.showDebugUI && (
        <>
          <button
            type="button"
            onClick={() => {
              console.log("@rootApp", rootApp);
            }}
          >
            DEBUG
          </button>
        </>
      )}

        </div>

        <p className="text-center text-[12px] font-font-family-body w-fit mx-auto">
          {getDateFixedCopyrightText(footerContent)}
        </p>
      </div>
      {/* <ul>
        <li ref={termAndConditions} className={styles.listItem}>
          <div onClick={() => navigateTo("/t&c")}>
            <a target="_blank">Ts & Cs</a>
          </div>
        </li>
        <li ref={franchise} className={styles.listItem}>
          <div onClick={() => navigateTo("/franchise/our-story")}>
            <a target="_blank">Franchise</a>
          </div>
        </li>
        <li ref={contact} className={styles.listItem}>
          <div onClick={() => navigateTo("/contact")}>
            <a target="_blank">Contact</a>
          </div>
        </li>
        <li ref={feedback} className={styles.listItem}>
          <div onClick={() => navigateTo(feedbackEmail)}>
            <a target="_blank">Feedback</a>
          </div>
        </li>
        <li ref={faq} className={styles.listItem}>
          <div onClick={() => navigateTo("/faq")}>
            <a target="_blank">FAQ</a>
          </div>
        </li>

        <li ref={privacyPolicy} className={styles.listItem}>
          <div onClick={() => navigateTo("/privacy-policy")}>
            <a target="_blank">Privacy Policy</a>
          </div>
        </li>

        <li ref={downloadMenu} className={styles.listItem}>
          <div>
            <a
              target="_blank"
              href="https://serveup.ams3.digitaloceanspaces.com/web-ecommerce/documents/Menu.pdf"
            >
              <a target="_blank">Ts & Cs</a>
            </div>
          </li>
          <li ref={privacyPolicy} className="cursor-pointer">
            <div
              onClick={() => navigateTo("/privacy-policy")}
              className="text-white font-mont2 text-[0.625rem] font-semibold tracking-[0.063rem]"
            >
              <a target="_blank">Privacy Policy</a>
            </div>
          </li>
          <li ref={franchise} className="cursor-pointer">
            <div
              onClick={() => navigateTo("/franchise/our-story")}
              className="text-white font-mont2 text-[0.625rem] font-semibold tracking-[0.063rem]"
            >
              <a target="_blank">Franchise</a>
            </div>
          </li>
          <li ref={contact} className="cursor-pointer">
            <div
              onClick={() => navigateTo("/contact")}
              className="text-white font-mont2 text-[0.625rem] font-semibold tracking-[0.063rem]"
            >
              <a target="_blank">Contact</a>
            </div>
          </li>
          <li ref={faq} className="cursor-pointer">
            <div
              onClick={() => navigateTo("/faq")}
              className="text-white font-mont2 text-[0.625rem] font-semibold tracking-[0.063rem]"
            >
              <a target="_blank">FAQ</a>
            </div>
          </li>
          <li ref={downloadMenu} className="cursor-pointer">
            <div className="text-white font-mont2 text-[0.625rem] font-semibold tracking-[0.063rem]">
              <a
                target="_blank"
                href="https://serveup.ams3.digitaloceanspaces.com/web-ecommerce/documents/Menu.pdf"
              >
                Downloads
              </a>
            </div>
          </li>
        </ul>

   */}
    </div>
  );
};


const getDateFixedCopyrightText = (text: string) => {
  const yearRegex = /\d{4}/;
  const match = yearRegex.exec(text);
  if (match) {
    const currentYear = new Date().getFullYear();
    if (match[0] !== String(currentYear)) {
      return text.replace(yearRegex, String(currentYear));
    }
  }
  return text;
};

export default Footer;
